import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  globalLoaderIsOpen: false,
  loaderIsOpen: false,
};

export const universityLoadingReducer = createSlice({
  name: "modal",
  initialState,
  reducers: {
    updateGlobalLoader: (state, action) => {
      state.globalLoaderIsOpen = action.payload;
    },
    updateLoader: (state, action) => {
      state.loaderIsOpen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateGlobalLoader, updateLoader } =
  universityLoadingReducer.actions;

export default universityLoadingReducer.reducer;
