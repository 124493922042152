import httpClient from "./httpClient";
import httpMasterdataClient from "./httpMasterdataClient";
import httpDashboardClient from "./httpDashboardClient";

export const onApiCall = async ({
  method,
  url,
  data,
  params = {},
  isFileUpload = false,
  controller = null,
}) => {
  return onApiCallAction({
    method,
    url,
    data,
    params,
    isFileUpload,
    controller,
    isMasterData: false,
  });
};
export const onMasterdataApiCall = async ({
  method,
  url,
  data,
  params = {},
  isFileUpload = false,
  controller = null,
}) => {
  return onApiCallAction({
    method,
    url,
    data,
    params,
    isFileUpload,
    controller,
    apiCallType: "master",
  });
};
const defaultHeaders = {
  Accept: "application/json",
};
export const onDashboardApiCall = async ({
  method,
  url,
  data,
  params = {},
  headers = defaultHeaders,
  isFileUpload = false,
  controller = null,
}) => {
  return onApiCallAction({
    method,
    url,
    data,
    params,
    headers,
    isFileUpload,
    controller,
    apiCallType: "dashboard",
  });
};

export const onApiCallAction = async ({
  method,
  url,
  data,
  params = {},
  headers,
  isFileUpload = false,
  controller = null,
  apiCallType = "",
}) => {
  console.log("baseUrls",url);
  // const { resetClick } = useContext(Appcontext);

  const constructHeaders = (headers) => {
    if (isFileUpload) {
      return {
        common: { ...headers, "Content-Type": "multipart/form-data" },
      };
    } else {
      return {
        common: { ...headers, "Content-Type": "application/json" },
      };
    }
  };
  try {
    const response = await (apiCallType == "master"
      ? httpMasterdataClient
      : apiCallType == "dashboard"
        ? httpClient
        : httpClient
    ).request({
      url,
      method,
      data,
      headers: constructHeaders(headers),
      params,
      signal: controller && controller.signal,
    });
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log("Err", error.response.data);

      // if (error.response.status == 401) {
      //   resetClick();
      // }
      return {
        data: error.response.data.error,
        data1: error.response.data,
        status: error.response.status,
      };
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // console.log(error.request);
      return {
        data: "api error",
      };
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
      return {
        data: "api error",
      };
    }
  }
};

export const getError = (error) =>
  (error.response && error.response.data && error.response.data.message) ||
  error.message ||
  error.toString();

// Master Data Services
export const getAllCountries = () => {
  return onApiCall({
    url: `/master/countries/`,
    method: "GET",
  });
};
export const getStates = (countryId) => {
  return onApiCall({
    url: `/master/states/${countryId}`,
    method: "GET",
  });
};
export const getCities = (params) => {
  return onApiCall({
    url: `/master/cities/${params.countryId}/${params.stateId}`,
    method: "GET",
  });
};
export const getEthinicities = () => {
  return onApiCall({
    url: `/master/ethinicities/`,
    method: "GET",
  });
};

export const getUniversity = () => {
  return onApiCall({
    url: `/master/universities/`,
    method: "GET",
  });
};

export const getCampus = (params = []) => {
  console.log("patmas",params)
  return onApiCall({
    url:
      params.length > 0
        ? `/master/campus/?UniversityId=` + params
        : `/master/campus/`,
    method: "GET",
  });
};

export const getFaculty = (params = []) => {
  return onApiCall({
    url:
      params.length > 0
        ? `/master/faculty/?CollegeId=` + params
        : `/master/faculty/`,
    method: "GET",
  });
};
export const getStudyPrograms = (params = []) => {
  return onApiCall({
    url:
      params.length > 0
        ? `/master/study-program/?FacultyId=` + params
        : `/master/study-program/`,
    method: "GET",
  });
};

export const getAcademicQualifications = () => {
  return onApiCall({
    url: `/master/academic-qualifications/`,
    method: "GET",
  });
};

export const getSector = () => {
  return onApiCall({
    url: `/master/sector/`,
    method: "GET",
  });
};

export const getCompensationDetails = () => {
  return onApiCall({
    url: `/master/compensation-details/`,
    method: "GET",
  });
};
export const getEmploymentStatusOptions = () => {
  return onApiCall({
    url: `/master/employment-status-options/`,
    method: "GET",
  });
};
export const getEnglishEquivalentSubtestTypes = () => {
  return onApiCall({
    url: `/master/english-equivalent-subtest-types/`,
    method: "GET",
  });
};

export const getEnglishEquivalentTestTypes = () => {
  return onApiCall({
    url: `/master/english-equivalent-test-types/`,
    method: "GET",
  });
};
export const getGrades = () => {
  return onApiCall({
    url: `/master/grades/`,
    method: "GET",
  });
};

export const getProgrameTypes = () => {
  return onApiCall({
    url: `/master/programme-types/`,
    method: "GET",
  });
};

export const getJobTypeDetails = () => {
  return onApiCall({
    url: `/master/job-type-details/`,
    method: "GET",
  });
};
export const getPaidOrUnpaid = () => {
  return onApiCall({
    url: `/master/paid-or-unpaid/`,
    method: "GET",
  });
};

export const getPersonCurrentStatus = () => {
  return onApiCall({
    url: `/master/person-current-status/`,
    method: "GET",
  });
};

export const getProgrammeTypes = () => {
  return onApiCall({
    url: `/master/programme-types/`,
    method: "GET",
  });
};

export const getScholarshipSubTypes = () => {
  return onApiCall({
    url: `/master/scholarship-subtypes/`,
    method: "GET",
  });
};

export const getScholarshipTypes = () => {
  return onApiCall({
    url: `/master/scholarship-types/`,
    method: "GET",
  });
};

export const getScopeOfStudies = (params = []) => {
  return onApiCall({
    url:
      params.length > 0
        ? `/master/scope-of-studies/?UniversityId=` + params
        : `/master/scope-of-studies/`,
    method: "GET",
  });
};

export const getSkills = () => {
  return onApiCall({
    url: `/master/skills/`,
    method: "GET",
  });
};

export const getSkillsCategory = () => {
  return onApiCall({
    url: `/master/skills-category/`,
    method: "GET",
  });
};

export const getStatusDetails = () => {
  return onApiCall({
    url: `/master/status-details/`,
    method: "GET",
  });
};

export const getYearOfStudies = () => {
  return onApiCall({
    url: `/master/year-of-studies/`,
    method: "GET",
  });
};

export const getDimensions = ({ builderApiController }) => {
  return onApiCall({
    url: `/dashboard/university/custom-report-builder/dimensions/`,
    method: "GET",
    controller: builderApiController,
  });
};


export const getGroup = ({ builderApiController }) => {
  return onApiCall({
    url: `/dashboard/university/custom-report-builder/groups/`,
    method: "GET",
    controller: builderApiController,
  });
};

export const getMatrics = ({ builderApiController, dimensionsParams }) => {
  return onApiCall({
    url: `/dashboard/university/custom-report-builder/metrics/`,
    method: "GET",
    controller: builderApiController,
    params: dimensionsParams,
  });
};

export const getFilters = ({ builderApiController, dimensionsParams }) => {
  return onApiCall({
    url: `/dashboard/university/custom-report-builder/filters/`,
    method: "GET",
    controller: builderApiController,
    params: dimensionsParams,
  });
};

export const getOpreations = ({ builderApiController, dimensionsParams }) => {
  return onApiCall({
    url: `/dashboard/university/custom-report-builder/operations/`,
    method: "GET",
    controller: builderApiController,
    params: dimensionsParams,
  });
};

export const getFilterOptions = ({ builderApiController, dimensionsParams }) => {
  return onApiCall({
    url: `/dashboard/university/custom-report-builder/filter-options/`,
    method: "GET",
    controller: builderApiController,
    params: dimensionsParams,
  });
};
export const getCustomBuilderChartData = ({ builderApiController, chartParams }) => {
  console.log("klkl", chartParams)
  return onApiCall({
    url: `/dashboard/university/custom-report-builder/data/`,
    method: "POST",
    controller: builderApiController,
    data: chartParams,
  });
};

export const getStudentsList = ({ builderApiController, studentParams }) => {
  return onApiCall({
    url: `/dashboard/university/custom-report-builder/students-list/`,
    method: "GET",
    controller: builderApiController,
    params: studentParams,
  });
};

export const getGenderRatio = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `/dashboard/university/analytical/demographic/total-students-by-gender/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};
export const getGenderPoputation = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `/dashboard/university/analytical/demographic/total-students-by-registration-status/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getPersonalitySummary = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `/dashboard/university/analytical/assessment/personality/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,

  });
};

export const getWorkInterest = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `/dashboard/university/analytical/assessment/work-interest`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getEmployability = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `/dashboard/university/analytical/assessment/employability`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getIndustrySector = ({ filters }) => {
  return onApiCall({
    url: `/dashboard/university/analytical/demographic/total-students-by-internship-sector`,
    method: "GET",
    params: filters

  });
};

export const getEnglishProficiency = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `/dashboard/university/analytical/assessment/english-proficiency`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};


export const getFutureOfWork = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `/dashboard/university/analytical/assessment/future-of-work`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getWorkValues = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `/dashboard/university/analytical/assessment/work-value`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};
export const getInternship = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `/dashboard/university/analytical/demographic/total-students-by-internship-status/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};
export const getInternshipCompanySector = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `/dashboard/university/analytical/demographic/total-students-by-internship-sector-name/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};
export const getQuizzesCompletion = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `/dashboard/analytical/quizzes/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};
export const getPersonalityAndMotivationDrilldown = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `dashboard/analytical/five-factor/6/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};
export const getWorkValuesDrilldown = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `dashboard/analytical/work-values/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getMasteryDrilldown = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `dashboard/analytical/work-values/1/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getQualityOfLifeDrilldown = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `dashboard/analytical/work-values/2/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getFellowshipDrilldown = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `dashboard/analytical/work-values/3/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getCreatingValueDrilldown = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `dashboard/analytical/work-values/4/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getEmployabilityDrilldown = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `dashboard/analytical/employability/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getIdeasAndOpportunitiesDrilldown = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `dashboard/analytical/employability/7/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getResourcesDrilldown = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `dashboard/analytical/employability/8/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getIntoActionDrilldown = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `dashboard/analytical/employability/9/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getFutureOfWorkDrilldown = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `dashboard/analytical/century-skills/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getCognitiveDrilldown = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `dashboard/analytical/century-skills/11/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getInterpersonalDrilldown = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `dashboard/analytical/century-skills/12/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getSelfLeadershipDrilldown = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `dashboard/analytical/century-skills/13/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getDigitalDrilldown = ({ filters, chartsLoadingController }) => {
  return onApiCall({
    url: `dashboard/analytical/century-skills/14/`,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};

export const getDrilldown = (url, { filters, chartsLoadingController }) => {
  return onApiCall({
    url,
    method: "GET",
    params: filters,
    controller: chartsLoadingController,
  });
};