import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import { logout } from "../../actions/auth";
import Footer from "../../components/footer/footer";
import TopNavbar from "../../components/navbar/navbar";
import { clearMessage } from "../../reducers/messageReducer";
import EventBus from "../../utilities/eventBus";

const MainWrapper = styled.div`
  background: #e5e5e5;
  min-height: 100vh;
  .space-left {
    margin-left: 400px;
    margin-right: 50px; 
  }
`;

function useWindowDimensions() {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  return {
    width,
    height,
  };
}

export default function Layout({ children }) {
  const { width } = useWindowDimensions();

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearMessage());
  }, [location.pathname]);

  useEffect(() => {
    EventBus.on("invalidToken", () => {
      dispatch(logout())
        .then(navigate("/login", { replace: true }))
        .catch(navigate("/login", { replace: true }));
    });

    return () => {
      EventBus.remove("invalidToken");
    };
  }, [dispatch, navigate]);

  const enableToggle = width < 1200;
  return (
    <MainWrapper>
      <TopNavbar enableToggle={enableToggle} />
      <div style={{minHeight: "calc(100vh - 230px)"}} className={!enableToggle ? "space-left" : ""}>
        {children}

      </div>
     <div className={!enableToggle ? "space-left" : ""}>
     <Footer className="space-left" />
      </div> 
    </MainWrapper>
  );
}
