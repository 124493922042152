export const API_ROUTES = {
  GET_CURRENT_USER_PROFILE: "/accounts/profile/",
  UPDATE_CURRENT_USER_PROFILE: "/accounts/profile/",
  CHANGE_CURRENT_USER_AVATAR: "/accounts/change_avatar/",
  LOGIN_URL: "/accounts/login/",
  LOGOUT_URL: "/accounts/logout/",
  SEND_EMAIL_OTP_PASSWORD_RESET: "/accounts/request_new_password/",
  RESEND_EMAIL_OTP_PASSWORD_RESET:
    "/accounts/request_new_password/resend_code/",
  VERIFY_EMAIL_OTP_PASSWORD_RESET:
    "/accounts/request_new_password/verify_code/",
  CHANGE_PASSWORD: "/accounts/change_password/",
  FORCE_CHANGE_PASSWORD: "/accounts/reset_password/",
  GET_CURRENT_USER_DETAILS_URL: "/accounts/me/",
  GET_UNIVERSITIES: "/universities/",
  GET_UNIVERSITY_BY_ID: "/universities/{}/",
  CREATE_UNIVERSITY: "/universities/{}/addresses/",
  CREATE_NEW_UNIVERSITY: "/universities/",
  DELETE_UNIVERSITY: "/universities/{}",
  UPDATE_UNIVERSITY_BY_ID: "/universities/{}/",
  CHANGE_UNIVERSITY_LOGO: "/universities/{}/change_logo/",
  GET_UNIVERSITY_LOCATIONS: "/universities/{}/addresses/",
  GET_UNIVERSITY_LOCATION_BY_ID: "/universities/{}/addresses/{}/",
  UPDATE_UNIVERSITY_LOCATION_BY_ID: "/universities/{}/addresses/{}/",
  DELETE_UNIVERSITY_LOCATION_BY_ID: "/universities/{}/addresses/{}/",
  GET_UNIVERSITY_USERS: "/users/",
  GET_DRILLDOWN_DATA: "/dashboard/university/analytical/results/assessment-get-by-type/",
  GET_UNIVERSITY_USER_BY_ID: "/universities/{}/users/",
  DELETE_UNIVERSITY_USER: "/users/{}/",
  UPDATE_UNIVERSITY_USER: "/users/{}/",
  CREATE_UNIVERSITY_USER: "/users/",
  UPDATE_STATUS_UNIVERSITY_USER: "/users/{}/status/",
  CREATE_USER_BY_UNIVERSITY_ID: "/universities/{}/users/",
  UPDATE_USER_BY_UNIVERSITY_ID: "/universities/{}/users/{}/",
  GET_USER_BY_UNIVERSITY_ID: "/universities/{}/users/{}/",
  DELETE_USER_BY_UNIVERSITY_ID: "/universities/{}/users/{}/",
  GET_UNIVERSITY_USER_DETAIL_BY_ID: "/users/{}/",
  GET_UNIVERSITY_USERS_MANAGMENT: "/university-students/{}/",
  UPDATE_UNIVERSITY_STUDENT_DETAILS: "/university-student/{}/",
  CREATE_UNIVERSITY_STUDENT: "/university-students/{}/",
  IMPORT_BULK_STUDENTS: "/validate-import-university-student/{}/",
  IMPORT_BULK_STUDENTS_AFTER_VALIDATION: "/import-university-student/{}/",
  DOWNLOAD_STUDENTS: "/download-university-list/{}/",
  SINGLE_REMINDER: "/send-single-reminder/{}/",
  MULTIPLE_REMINDERS: "/send-multiple-reminders/{}/",
  DOWNLOAD_STUDENTS_TEMPLATE: "/university-students-excel-template-export/{}/",
  CREATE_ROLE: "/university-roles/{}/",
  GET_ROLES: "/university-roles/{}/",
  UPDATE_ROLE: "/university-role/{}/",

};
export const MASTERDATA_ROUTES = {
  ROLES_GET_URL: "/roles/",
  GET_COUNTRIES: "/scroll-list/all/loc/country/",
  GET_STATES_BY_COUNTRY: "/scroll-list/hint/{}/state/",
  GET_CITY_BY_COUNTRY_AND_STATE: "/scroll-list/{}/{}/city/",
};
