import { BrowserRouter } from "react-router-dom";
import UserCreationLayout from "./layouts/userCreation/userCreationLayout";

import { routes } from "./routes";
import "./App.css";
import { routeGenerator } from "./utilities/commonFunctions";
import Layout from "./layouts/layout/layout";
import { useDispatch, useSelector } from "react-redux";
import UniversityLoader from "./components/universityLoader/universityLoader";
import { useEffect } from "react";
import { getCurrentUser, logout } from "./actions/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cookiesService from "./services/cookies.service";
import "jspdf-autotable";
import LanguageSwitcher from "./components/languageSwitcher/languageSwitcher";
import { deviceStore } from "./utilities/devices";
import { subscribe, useSnapshot } from "valtio";

function App() {
  useSnapshot(deviceStore);
  deviceStore.isBrowser().then(() => {});
  const checkbrowser = deviceStore.checkbrowser;
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const globalLoaderIsOpen = useSelector(
    (state) => state.loader.globalLoaderIsOpen
  );
  const token = cookiesService.getItem("token");

  useEffect(() => {
    const token = cookiesService.getItem("token");
    if (token) {
      dispatch(getCurrentUser());
    } else {
      dispatch(logout());
    }
  }, [token]);

  const Component = !isLoggedIn ? UserCreationLayout : Layout;

  if (process.env.NODE_ENV == "production")
    console.log = function no_console() {};

  return (
    <>
      <BrowserRouter basename={"/"}>
        <LanguageSwitcher />
        <Component>{routeGenerator(routes, isLoggedIn)}</Component>
      </BrowserRouter>
      <ToastContainer />

      {globalLoaderIsOpen && <UniversityLoader />}
    </>
  );
}

export default App;
