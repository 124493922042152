import { createSlice } from "@reduxjs/toolkit";
import { getRoles } from "../actions/userManagement";

const initialState = {
  data: undefined,
  totalRows: 0,
  page: 1,
  perPage: 10,
  sortField: null,
  search: null,
  roles: null,
  sortDirection: null,
};

export const roleManagementReducer = createSlice({
  name: "roleManagement",
  initialState,
  reducers: {
    setTotalRows: (state, action) => {
      state.totalRows = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setSortField: (state, action) => {
      state.sortField = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setSortDirection: (state, action) => {
      state.sortDirection = action.payload;
    },
    resetAll: (state) => {
      state.data = undefined;
      state.totalRows = 0;
      state.page = 1;
      state.perPage = 10;
      state.sortField = null;
      state.search = null;
      state.roles = null;
      state.sortDirection = null;
    },
  },
  extraReducers: {
    [getRoles.fulfilled]: (state, action) => {
      console.log("role management reducerrrr",state, action);
      state.data = action.payload.data;
      state.totalRows = action.payload.count
    },
    [getRoles.rejected]: (state) => {
      state.data = [];
      state.totalRows = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTotalRows,
  setPage,
  setPerPage,
  setSortField,
  setSearch,
  setRoles,
  setSortDirection,
  resetAll,
} = roleManagementReducer.actions;

export default roleManagementReducer.reducer;
