import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./reducers/authReducer";
import messageReducer from "./reducers/messageReducer";
import universityLoadingReducer from "./reducers/universityLoadingReducer";
import universityModalReducer from "./reducers/universityModalReducer";
import studentModalReducer from "./reducers/studentModalReducer";
import universityReducer from "./reducers/universityReducer";
import userManagementReducer from "./reducers/userManagementReducer";
import dashboardReducer from "./reducers/dashboardReducer";
import universityUsersManagementReducer from "./reducers/universityUsersManagementReducer";
import meetingModalReducer from "./reducers/meetingModalReducer";
import drillDownStudentTableReducer from "./reducers/drillDownStudentTableReducer";
import  drillDownModalReducer  from "./reducers/drillDownStudentTableModalReducer";
import ad2AnalyticalReducer from "./reducers/ad2analyticalReducer";
import roleManagementReducer from "./reducers/roleManagementReducer";

export const store = configureStore({
  reducer: {
    modal: universityModalReducer,
    loader: universityLoadingReducer,
    auth: authReducer,
    ad2: ad2AnalyticalReducer,
    message: messageReducer,
    userManagement: userManagementReducer,
    university: universityReducer,
    drillDownTableReducer:drillDownStudentTableReducer,
    drillDownModalReducer: drillDownModalReducer,
    dashboard: dashboardReducer,
    universityUsersManagementReducer: universityUsersManagementReducer,
    studentModalReducer: studentModalReducer,
    meeting: meetingModalReducer,
    roleManagement: roleManagementReducer,
  },
});
