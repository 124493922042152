import React from "react";
import { CONSTANTS } from "./constants/constants";

export const routes = [
  {
    path: "/student-management",
    lazyRoute: React.lazy(() => import("./pages/studentManagement/index")),
    isAuthRoute: true,
    authorizationFor: CONSTANTS.STUDENT_MANAGEMENT_AUTHORIZATION_FOR,
  },
  {
    path: "/",
    lazyRoute: React.lazy(() => import("./pages/homePage/homePage")),
    isAuthRoute: true,
    authorizationFor: CONSTANTS.HOME_AUTHORIZATION_FOR,
  },
  {
    path: "/user-management/*",
    lazyRoute: React.lazy(() =>
      import("./pages/userManagementPage/userManagementPage")
    ),
    isAuthRoute: true,
    authorizationFor: CONSTANTS.USER_MANAGEMENT_AUTHORIZATION_FOR,
  },
  {
    path: "/role-management/*",
    lazyRoute: React.lazy(() =>
      import("./pages/roleManagement/roleManagementPage")
    ),
    isAuthRoute: true,
    authorizationFor: CONSTANTS.USER_MANAGEMENT_AUTHORIZATION_FOR,
  },
  {
    path: "/analytical-dashboard/*",
    lazyRoute: React.lazy(() =>
      import("./pages/analyticalDashboardV2/ad2DashboardPage")
    ),
    isAuthRoute: true,
    authorizationFor: CONSTANTS.ANALYTICAL_DASHBOARD_AUTHORIZATION_FOR,
  },
  // {
  //   path: "/coaching-dashboard/*",
  //   lazyRoute: React.lazy(() =>
  //     import("./pages/coachingDashboardPage/coachingDashboardPage")
  //   ),
  //   isAuthRoute: true,
  //   authorizationFor: CONSTANTS.COACHING_DASHBOARD_AUTHORIZATION_FOR,
  // },
  {
    path: "/customReportBuilder/*",
    lazyRoute: React.lazy(() =>
      import("./pages/customReportBuilder/CustomReportBuilder")
    ),
    isAuthRoute: true,
    authorizationFor: CONSTANTS.CUSTOM_REPORT_BUILDER_AUTHORIZATION_FOR,
  },
  {
    path: "/coaching-dashboard/*",
    lazyRoute: React.lazy(() =>
      import("./pages/coachingDashboardPageNew/coachingDashboardPage")
    ),
    isAuthRoute: true,
    authorizationFor: CONSTANTS.COACHING_DASHBOARD_AUTHORIZATION_FOR,
  },
  {
    path: "/scheduling/*",
    lazyRoute: React.lazy(() =>
      import("./pages/scheduling/scheduling")
    ),
    isAuthRoute: true,
    authorizationFor: CONSTANTS.SCHEDULING_AUTHORIZATION_FOR,
  },
  {
    path: "/university",
    lazyRoute: React.lazy(() =>
      import("./pages/universityPage/universityPage")
    ),
    isAuthRoute: true,
    authorizationFor: CONSTANTS.UNIVERSITY_AUTHORIZATION_FOR,
  },
  {
    path: "/landing-page",
    lazyRoute: React.lazy(() =>
      import("./pages/universityLandingPage/universityLandingPage")
    ),
  },
  {
    path: "/landing-page/add-university",
    lazyRoute: React.lazy(() =>
      import("./pages/universityLandingPage/AddUniversity")
    ),
  },
  {
    path: "/landing-page/detail/:id",
    lazyRoute: React.lazy(() =>
      import("./pages/universityPage/universityDetailPage")
    ),
    isAuthRoute: true,
    authorizationFor: CONSTANTS.UNIVERSITY_AUTHORIZATION_FOR,
  },
  {
    path: "/landing-page/detail/:id/user-managment",
    lazyRoute: React.lazy(() =>
      import("./pages/universityUserManagementPage/userManagementPage")
    ),
    isAuthRoute: true,
    authorizationFor: CONSTANTS.USER_MANAGEMENT_AUTHORIZATION_FOR,
  },
  {
    path: "/landing-page/detail/:id/user-managment/*",
    lazyRoute: React.lazy(() =>
      import("./pages/universityUserManagementPage/userManagementPage")
    ),
    isAuthRoute: true,
    authorizationFor: CONSTANTS.USER_MANAGEMENT_AUTHORIZATION_FOR,
  },
  {
    path: "/settings/*",
    lazyRoute: React.lazy(() => import("./pages/settingsPage/settingsPage")),
    isAuthRoute: true,
    authorizationFor: CONSTANTS.SETTINGS_AUTHORIZATION_FOR,
  },
  {
    path: "/help/*",
    lazyRoute: React.lazy(() => import("./pages/helpPage/helpPage")),
    isAuthRoute: true,
    authorizationFor: CONSTANTS.HELP_AUTHORIZATION_FOR,
  },
  {
    path: "/login",
    lazyRoute: React.lazy(() => import("./pages/login/loginPage")),
  },
  {
    path: "/forgot-password",
    lazyRoute: React.lazy(() =>
      import("./pages/forgotPasswordPage/forgotPasswordPage")
    ),
  },
  {
    path: "/create-password",
    lazyRoute: React.lazy(() =>
      import("./pages/createPasswordPage/createPasswordPage")
    ),
  },
];
