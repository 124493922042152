import styled from "styled-components";
import { ReactComponent as Loader } from "../../assets/svg/loader.svg";
import {
  LoaderBackdrop,
  LoaderWrapper,
} from "../../pages/analyticalDashboardPage/commonStyles";

const LoaderFixed = styled.div`
  height: 100vh;
  width: 100vw;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 3000;
`;
const LoaderDynamic = styled.div``;


export default function UniversityLoader({ isFixed = true }) {
  const LoaderTopLevel = isFixed ? LoaderFixed : LoaderDynamic;
  return (
    <LoaderTopLevel>
      <LoaderBackdrop />
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    </LoaderTopLevel>
  );
}
