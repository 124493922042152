import { createSlice } from "@reduxjs/toolkit";
//C:\mynext\university\src\reducers\studentModalReducer.js
const initialState = {
    modalIsOpen: false,
    cache: null,
    // mode: "normal",
    // id: null
};

export const meetingModalReducer = createSlice({
    name: "meetingModalReducer",
    initialState,
    reducers: {
        updateModal: (state, action) => {
            if (typeof action.payload === "boolean") {
                state.modalIsOpen = action.payload;
                state.cache = null;
            } else {
                state.modalIsOpen = action.payload.modalIsOpen;
                state.cache = action.payload.cache;
                // state.mode = action.payload.mode;
                // state.id = action.payload.id;
            }
        },
    },
});

// Action creators are generated for each case reducer function
export const { updateModal } = meetingModalReducer.actions;

export default meetingModalReducer.reducer;
