export const CONSTANTS = {
  version: "1.0.0",
  HOME_AUTHORIZATION_FOR: ["student_managment_view", "student_managment_manage", "student_managment_action", "analytical_dashboard_view", "analytical_dashboard_manage" , "coaching_dashboard_view", "coaching_dashboard_manage", "crb_manage", "scheduling_view" ,"scheduling_manage", "user_management_view", "user_management_manage"], //available for all
  STUDENT_MANAGEMENT_AUTHORIZATION_FOR: ["student_managment_view", "student_managment_manage", "student_managment_action"],
  USER_MANAGEMENT_AUTHORIZATION_FOR: ["user_management_view", "user_management_manage"],
  ROLE_MANAGEMENT_AUTHORIZATION_FOR: ["user_management_view", "user_management_manage"], //same as user management
  ANALYTICAL_DASHBOARD_AUTHORIZATION_FOR: ["analytical_dashboard_view", "analytical_dashboard_manage"],
  CUSTOM_REPORT_BUILDER_AUTHORIZATION_FOR: ["crb_manage"],
  COACHING_DASHBOARD_AUTHORIZATION_FOR: ["coaching_dashboard_view", "coaching_dashboard_manage"],
  SCHEDULING_AUTHORIZATION_FOR: ["scheduling_view", "scheduling_manage"],
  UNIVERSITY_AUTHORIZATION_FOR: ["user_management_view", "user_management_manage"], //same as user management
  SETTINGS_AUTHORIZATION_FOR: ["student_managment_view", "student_managment_manage", "student_managment_action", "analytical_dashboard_view", "analytical_dashboard_manage" , "coaching_dashboard_view", "coaching_dashboard_manage", "crb_manage", "scheduling_view" ,"scheduling_manage", "user_management_view", "user_management_manage"], //available for all
  HELP_AUTHORIZATION_FOR: ["student_managment_view", "student_managment_manage", "student_managment_action", "analytical_dashboard_view", "analytical_dashboard_manage" , "coaching_dashboard_view", "coaching_dashboard_manage", "crb_manage", "scheduling_view" ,"scheduling_manage", "user_management_view", "user_management_manage"], //available for all
  SEMESTER_DATA: [
    {
      "label": "Semester 1",
      "value": "1"
    },
    {
      "label": "Semester 2",
      "value": "2"
    },
    {
      "label": "Semester 3",
      "value": "3"
    },
    {
      "label": "Semester 4",
      "value": "4"
    },
    {
      "label": "Semester 5",
      "value": "5"
    },
    {
      "label": "Semester 6",
      "value": "6"
    },
    {
      "label": "Semester 7",
      "value": "7"
    },
    {
      "label": "Semester 8",
      "value": "8"
    }
  ]
};
