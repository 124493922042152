import { createSlice } from "@reduxjs/toolkit";
import { getCurrentUser, login, logout } from "../actions/auth";
import cookiesService from "../services/cookies.service";

const token = cookiesService.getItem("token");
const universityId = cookiesService.getItem("universityId");

const initialState = (token && universityId)
  ? { isLoggedIn: true, user: {university: {universityId: universityId}} }
  : { isLoggedIn: false, user: null };

export const authReducer = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      if (action && action.payload && action.payload.updateLogin) {
        state.isLoggedIn = true;
      }
    },
    [login.rejected]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [getCurrentUser.fulfilled]: (state, action) => {
      state.user = action.payload.user;
    },
    [getCurrentUser.rejected]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

export default authReducer.reducer;
