import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function LanguageSwitcher() {
  const [searchParams, setSearchParams] = useSearchParams();
  const lang = searchParams.get("lang");

  const { i18n } = useTranslation();

  useEffect(() => {
    if (lang !== null) {
      i18n.changeLanguage(lang);
    } else {
      setSearchParams({ lang: i18n.language }, { replace: true });
    }
  }, [i18n.language, lang]);

  return null;
}
