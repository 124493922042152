import styled from "styled-components";

import { Colors } from "../../utilities/colors";

export default function Button(props) {
  // styling starts
  const CustomBtn = styled.button`
    width: ${props.width};
    background: ${(props) =>
      props.secondary
        ? Colors.light
        : "linear-gradient(90deg, #FA4AA1 0%, #504F8C 100%)"};
    border-radius: 128px;
    outline: none;
    border: ${(props) => (props.secondary ? "1px solid #D04C8D" : "0px")};
    font-weight: 600;
    font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : "24px")};
    color: ${(props) => (props.secondary ? Colors.primaryText : Colors.light)};
    padding: 13px 0px;
    margin: ${(props) => (props.margin ? props.margin : "15px 0")};
    // min-width: ${(props) => (props.minWidth ? props.minWidth : "200px")};
    width: 192px;
    @media screen (max-width: 576px) {
      width: 100%;
    }
    // min-width:fit-content;
    cursor: pointer;
    &:hover {
      color: ${Colors.light};
      background: ${(props) =>
        props.secondary
          ? Colors.pink
          : " linear-gradient(90deg, #504F8C 0%, #FA4AA1 100%);"};
    }
    @media (max-width: 576px) {
      width: 100%;
      font-size: 14px;
      max-width: ${props.mobWidth};
    }
  `;
  // styling ends

  return (
    <CustomBtn secondary={props.secondary} margin={props.margin} {...props}>
      {props.name}
    </CustomBtn>
  );
}
