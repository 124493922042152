import { MASTERDATA_ROUTES } from "../constants/apiRoutes";
import { stringFormat } from "../utilities/commonFunctions";
import { onApiCall, onMasterdataApiCall } from "./CommonApi";

class MasterdataService {
  getRoles() {
    return onApiCall({
      url: MASTERDATA_ROUTES.ROLES_GET_URL,
      method: "GET",
    });
  }

  getCountries() {
    return onMasterdataApiCall({
      url: MASTERDATA_ROUTES.GET_COUNTRIES,
      method: "POST",
      data: {
        search_key: "",
      },
    }).then((response) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.length
      ) {
        response.data = response.data.data.map((x) => {
          return { label: x, value: x };
        });
      }
      return response && response.data;
    });
  }

  getStates(countryName) {
    return onMasterdataApiCall({
      url: stringFormat(MASTERDATA_ROUTES.GET_STATES_BY_COUNTRY, countryName),
      method: "POST",
      data: {
        search_key: "",
      },
    }).then((response) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.length
      ) {
        response.data = response.data.data.map((x) => {
          return { label: x, value: x };
        });
      }
      return response && response.data;
    });
  }

  getCities(countryName, stateName) {
    return onMasterdataApiCall({
      url: stringFormat(
        MASTERDATA_ROUTES.GET_CITY_BY_COUNTRY_AND_STATE,
        countryName,
        stateName
      ),
      method: "POST",
      data: {
        search_key: "",
      },
    }).then((response) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.length
      ) {
        response.data = response.data.data.map((x) => {
          return { label: x, value: x };
        });
      }
      return response && response.data;
    });
  }
}

export default new MasterdataService();
