import { API_ROUTES } from "../constants/apiRoutes";
import { stringFormat } from "../utilities/commonFunctions";
import { onApiCall } from "./CommonApi";
import masterdataService from "./masterdata.service";

class UserManagementService {
  getMasterdata() {
    return masterdataService.getRoles().then((response) => {
      return response.data.map((x) => ({ value: x.id, label: x.name }));
    });
  }

  getUsers(params, filtering, controller) {
    return onApiCall({
      url: `${API_ROUTES.GET_UNIVERSITY_USERS}?${filtering}`,
      method: "GET",
      params: params,
      controller,
    });
  }
  getDrilldown = (url, { params, controller }) => {
    return onApiCall({
      url,
      method: "GET",
      params: params,
      controller,
    });
  };
  getUserById(userId) {
    return onApiCall({
      url: stringFormat(API_ROUTES.GET_UNIVERSITY_USER_DETAIL_BY_ID, userId),
      method: "GET",
    });
  }
  createUser(userData) {
    return onApiCall({
      url: API_ROUTES.CREATE_UNIVERSITY_USER,
      method: "POST",
      data: userData,
    });
  }
  createUserByUniversity(userData, id) {
    return onApiCall({
      url: stringFormat(API_ROUTES.CREATE_USER_BY_UNIVERSITY_ID, id),
      method: "POST",
      data: userData,
    });
  }
  updateUser(userId, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.UPDATE_UNIVERSITY_USER, userId),
      method: "PUT",
      data: userData,
    });
  }
  updateUserWithUniversityId(id, userId, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.UPDATE_USER_BY_UNIVERSITY_ID, id, userId),
      method: "PUT",
      data: userData,
    });
  }
  getUserByIdWithUniversityId(id, userId) {
    return onApiCall({
      url: stringFormat(API_ROUTES.GET_USER_BY_UNIVERSITY_ID, id, userId),
      method: "GET",
    });
  }
  deleteUser(userId) {
    return onApiCall({
      url: stringFormat(API_ROUTES.DELETE_UNIVERSITY_USER, userId),
      method: "DELETE",
    });
  }
  updateUserStatusById(userId, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.UPDATE_STATUS_UNIVERSITY_USER, userId),
      method: "POST",
      data: userData,
    });
  }
  deleteByUserUniversity(id, userId) {
    return onApiCall({
      url: stringFormat(API_ROUTES.DELETE_USER_BY_UNIVERSITY_ID, id, userId),
      method: "DELETE",
    });
  }
  getUserManagmentUsers(id, params, controller) {
    return onApiCall({
      url: stringFormat(API_ROUTES.GET_UNIVERSITY_USERS_MANAGMENT, id),
      method: "GET",
      params: params,
      controller,
    });
  }
  updateUniversityStudentById(userId, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.UPDATE_UNIVERSITY_STUDENT_DETAILS, userId),
      method: "PUT",
      params: userData,
    });
  }
  createStudent(id, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.CREATE_UNIVERSITY_STUDENT, id),
      method: "POST",
      data: userData,
    });
  }
  updateSingleUniversityStudentById(userId, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.UPDATE_UNIVERSITY_STUDENT_DETAILS, userId),
      method: "PUT",
      data: userData,
    });
  }
  importBulkStudents(userId, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.IMPORT_BULK_STUDENTS, userId),
      method: "POST",
      data: userData,
    });
  }
  importBulkStudentsAfterValidation(userId, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.IMPORT_BULK_STUDENTS_AFTER_VALIDATION, userId),
      method: "POST",
      data: userData,
    });
  }
  importBulkStudentSingalValidation(userId, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.IMPORT_BULK_STUDENTS, userId),
      method: "PUT",
      data: userData,
    });
  }
  downloadStudent(userId, params) {
    return onApiCall({
      url: stringFormat(API_ROUTES.DOWNLOAD_STUDENTS, userId),
      method: "GET",
      params: params
    });
  }
  sendSingleReminder(userId, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.SINGLE_REMINDER, userId),
      method: "POST",
      data: userData,
    });
  }
  sendMultipleReminders(userId, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.MULTIPLE_REMINDERS, userId),
      method: "POST",
      data: userData,
    });
  }
  downloadStudentTemplate(userId, params) {
    return onApiCall({
      url: stringFormat(API_ROUTES.DOWNLOAD_STUDENTS_TEMPLATE, userId),
      method: "GET",
      params: params
    });
  }
  getRoleFeatures = () => {
    return onApiCall({
      url: `/university-role-features/`,
      method: "GET",
    });
  };
  createRole = (formdata, id) => {
    return onApiCall({
      url: `/university-roles/${id}/`,
      method: "POST",
      data: formdata
    });
  };
  getRoles = (params, filtering, controller, id) => {
    return onApiCall({
      url: `/university-roles/${id}/?${filtering}`,
      method: "GET",
      params: params,
      controller,
    });
  };
  getRoleDetails = (id) => {
    return onApiCall({
      url: `/university-role/${id}/`,
      method: "GET"
    });
  };
  updateRoleDetails = (formdata, id) => {
    return onApiCall({
      url: `/university-role/${id}/`,
      method: "PUT",
      data: formdata
    })
  };
}

export default new UserManagementService();
