import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import CoverImage from "../../assets/universityLoginCover.png";

// styling starts
const LayoutWrapper = styled.section`
  display: flex;
  // height: 100vh;
  background: ${Colors.secondaryColor};
  @media (max-width: 1024px) {
    display: block;
  }
`;

const ImageSection = styled.div`
  width: 490px;
  height: 180px;
`;
const CoverImageWrapper = styled.div`
  position: relative;
  line-height: 0px;
`;
const Image = styled.img`
  height: 90vh;
  width: 83%;
`;

const GradientCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0px;
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(87, 80, 141, 0.4) 0%,
    rgba(245, 75, 161, 0.4) 100%
  );
  border-radius: 0px 60px 60px 0px;
  width: 83%;
`;
const PageContentSection = styled.div`
  overflow-y: auto;
  flex-grow: 2;
  padding: 2rem 2rem;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

// styling ends

export default function UserCreationLayout({ children, sidebarImg }) {
  return (
    <LayoutWrapper>
      <ImageSection className="d-none d-md-none d-sm-none d-xs-none d-lg-block">
        <CoverImageWrapper>
          <Image src={sidebarImg ? sidebarImg : CoverImage} alt="Cover image" />
          <GradientCover />
        </CoverImageWrapper>
      </ImageSection>
      <PageContentSection>{children}</PageContentSection>
    </LayoutWrapper>
  );
}
