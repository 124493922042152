import { Modal } from "react-bootstrap";
import styled from "styled-components";
const Close = styled.span`
cursor:pointer;
`;

function AlertModal(props) {
  return (
    <div>
      <Modal show={props.show} onHide={props.close} centered>
        <div style={{padding:'40px 0 0 0',height:'220px'}}>
          <div style={{textAlign: 'center'}}>{props.content}</div>
        </div>
      </Modal>
    </div>
  );
}

export default AlertModal;
