import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  savedInternshipDates: {},
};

export const ad2AnalyticalReducer = createSlice({
  name: "ad2",
  initialState,
  reducers: {
    updateInternshipDates: (state, action) => {
      state.savedInternshipDates = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateInternshipDates } = ad2AnalyticalReducer.actions;

export default ad2AnalyticalReducer.reducer;
