import axios from "axios";
import EventBus from "../utilities/eventBus";
import cookiesService from "./cookies.service";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  //baseURL: 'https://api-uat-mynext.techaira.com/api/',
  timeout: 36000,
});


httpClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = cookiesService.getItem("token");
    //console.log("Token", token);
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
httpClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // Error
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.data);
      if (error.response.status === 401) {
        cookiesService.removeItem("token");
        EventBus.dispatch("invalidToken");
      }
      // console.log(error.response.status);
      // console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    // console.log(error.config);

    return Promise.reject(error);
  }
);

export default httpClient;
