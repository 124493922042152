import { API_ROUTES } from "../constants/apiRoutes";
import { onApiCall } from "./CommonApi";

class AuthService {
  login(email, password) {
    return onApiCall({
      url: API_ROUTES.LOGIN_URL,
      method: "POST",
      data: {
        email,
        password,
      },
    });
  }
  logout() {
    return onApiCall({
      url: API_ROUTES.LOGOUT_URL,
      method: "POST",
    });
  }
  getCurrentUserDetails() {
    return onApiCall({
      url: API_ROUTES.GET_CURRENT_USER_DETAILS_URL,
      method: "GET",
    });
  }
  sendEmailOtp(email) {
    return onApiCall({
      url: API_ROUTES.SEND_EMAIL_OTP_PASSWORD_RESET,
      method: "POST",
      data: { email },
    });
  }
  resendEmailOtp(email) {
    return onApiCall({
      url: API_ROUTES.RESEND_EMAIL_OTP_PASSWORD_RESET,
      method: "POST",
      data: { email },
    });
  }
  verifyEmailOtp(email, code) {
    return onApiCall({
      url: API_ROUTES.VERIFY_EMAIL_OTP_PASSWORD_RESET,
      method: "POST",
      data: { email, code },
    });
  }
  changePassword(currentPassword, newPassword, confirmNewPassword) {
    return onApiCall({
      url: API_ROUTES.CHANGE_PASSWORD,
      method: "POST",
      data: {
        current_password: currentPassword,
        new_password: newPassword,
        confirm_password: confirmNewPassword,
      },
    });
  }
  forceChangePassword(uid, token, newPassword, confirmNewPassword) {
    return onApiCall({
      url: API_ROUTES.FORCE_CHANGE_PASSWORD,
      method: "POST",
      data: {
        uid: uid,
        token: token,
        new_password: newPassword,
        confirm_password: confirmNewPassword,
      },
    });
  }
}

export default new AuthService();
