import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

export const dashboardReducer = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboard: (state, action) => {
      state.data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDashboard } = dashboardReducer.actions;

export default dashboardReducer.reducer;
