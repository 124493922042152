import { t } from "i18next";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const FooterWrapper = styled.footer`
    margin-top: 100px;
    border-top: 2px solid #B5B5B6;
    p {
        font-size: 12px;
        line-height: 16px;
        color: #9A9A9A;
    }
`;
export default function Footer() {
    return (
        <Container fluid="md">
            <Row>
                <Col>
                    <FooterWrapper className="footer py-3">
                        <div className="d-md-flex justify-content-md-between">
                            <p>{t("Locate us:")}<br />
                                6th Floor, Surian Tower, 1, Jalan PJU 7/3, Mutiara Damansara,<br />
                                47810 Petaling Jaya, Selangor
                            </p>
                            
                        </div>
                    </FooterWrapper>
                </Col>
            </Row>
        </Container>
    )
} 