class CookiesService {
  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  setItem(key, value) {
    return this.setCookie(key, value, 1);
  }

  getItem(key) {
    return this.getCookie(key);
  }

  removeItem(key) {
    const expiredDate = new Date(1970, 1, 1);
    let expires = "expires=" + expiredDate.toUTCString();
    document.cookie = `${key}=;${expires};path=/`;
  }

  getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}

export default new CookiesService();
